// $primary: #a51616;
$primary: #3187b2; /* MAIN COLOR */
$secondary: #a667a6; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;



h1, h2, h3 {font-family: 'Righteous', cursive;}

a {font-family: 'Righteous', cursive;}
p {font-family: 'Alegreya Sans SC', sans-serif;}


.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.row {margin-right: 0px;
    margin-left: 0;}

.pad {margin-right: -15px;
    margin-left: -15px;
	max-height: 600px;
	overflow: hidden;
	
	
	@media (max-width: 768px) {
		max-height: 1200px;
	}
	
	}


/* =========== Navigation ============ */

.navbar-default {
    background-color: $wht;
	padding: 15px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-bottom: 8px solid $secondary;
	border-top: 0px solid transparent;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	width: 100% !important;

	@media (max-width: 767px) {
		padding: 5px;
	}


}
.navbar-default .navbar-nav > li > a {
    color: $primary;
    font-size: 2.25em;
	margin-top: 10px;

	@media (max-width: 767px) {
		line-height: 5px;
		margin-top: 5px;
	}

	@media (max-width: 1199px) {
		font-size: 1.5em;
	}
}
.navbar-default .navbar-nav > li > a:hover {
    color: $secondary;
}
.navbar-default .navbar-nav > li > a:focus {
    color: #B5B5B5;
}
.navbar-brand {
    padding: 9px 15px;
}
.navbar-default .navbar-nav > .active>a, 
.navbar-default .navbar-nav > .active>a:hover,
.navbar-default .navbar-nav > .active>a:focus {
    background-color: transparent;
    color: #129CF3;
} 

.navbar-toggle {margin-right: 0px !important;
				background-color: $wht;}

.navbar-toggle:hover {background-color: $secondary;}

.navbar-nav {margin: 0px;}

.icon-bar {background-color: $secondary;}



/* =========== Header ============ */
.header {
    background-image: url("../img/banner.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 50% 10%;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
	padding-left: 950px;
	
	@media (max-width: 1440px) {
		padding-left: 0px;
		background-position: 30% 10%;
	}
	
	@media (max-width: 1199px) {
		padding-left: 0px;
	}

	@media (max-width: 1024px) {
		background-attachment: scroll;
		    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
		background-position: 30% 10%;
	}

	@media (max-width: 767px) {
		padding-left: 0px;
		background-position: 30% 30%;
	}
    
}


.header-overlay {
    background-color: rgba(31, 31, 31, 0.55);
	padding: 25px 175px 50px 175px;

	@media (max-width: 767px) {
		padding: 5px 5px 25px 5px;
	}

	@media (max-width: 1199px) {
		padding: 5px 5px 25px 5px;
		text-align: center;
	}
}

.logo {width: 100%;
	   padding-bottom: 25px;
}
.header h1 {
    color: #fff;
    font-size: 3em;
    font-weight: 300;
    line-height: 45px;
	text-shadow: 3px 3px $secondary;
    text-transform: uppercase;

	@media (max-width: 767px) {
	font-size: 2.5em !important;
	text-align: center;
	}
}
.header p {
    color: #fff;
    font-size: 1.5em;
    line-height: 28px;
    font-weight: 300;
	
	@media (max-width: 767px) {
	text-align: center;
	}
}

/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    background-color: $secondary;
    border-color: $primary;
	   color: $wht;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 3px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
	 
	 border-radius: 50px;

}
.btn-download:hover {
    background-color: $primary;
    color: $wht;

}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;


	@media (max-width: 767px) {

		margin-top: 10px;
	}



	@media (max-width: 1024px) {

		margin-top: 10px;
	}
}

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

/*======= LOGO ====== */

.logo {
  width: 100%;
  max-width: 210px;
  margin-left: 0px;
  padding: 0px;
}


/* ======== FEATURES ======== */

.feature {padding: 50px 0px 0px 0px;
		  
		  	@media (max-width: 767px) {
				text-align: center;
				padding: 0px;
			}
}

.feature h1 {font-size: 4em;
			 color: $primary;
			 text-shadow: 2px 2px $secondary;
			 
			 
			@media (max-width: 767px) {
					font-size: 3em;
			}
}

.feature p {font-size: 2em;
			margin-bottom: 50px;
			
			@media (max-width: 1024px) {
					font-size: 1.5em;
					line-height: 30px;
				}			
}

.feature-one {
    background-image: url("../img/img1.jpg");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
	padding: 100px;

			@media (max-width: 767px) {
				text-align: center;
				padding: 0px;
			}

			@media (max-width: 1024px) {
				background-position: 90% 50%;
				padding: 0px;
			}
			
			@media (max-width: 1199px){
				padding: 0px;
			}
			@media (max-width: 1440px) {
				padding: 15px;
			}
}

.feature-one-overlay {
    background-color: rgba(31, 31, 31, 0.55);
	padding: 45px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.75), 0 6px 20px 0 rgba(0, 0, 0, 0.75);
	min-height: 421px; 

		@media (min-width: 1024px) and (max-width: 1055px) {
				padding: 25px !important;
				min-height: 375px; 
			}
		
		@media (max-width: 1440px) {
				padding: 25px;
				min-height: 346px; 
			}
}

.feature-one  h1 {color: #fff;
						font-size: 3em;
						font-weight: 300;
						text-transform: uppercase;
						text-shadow: 3px 3px $secondary;

				@media (min-width: 991px) and (max-width: 1174px) {
					font-size: 2em;
				}
				
	} 

.feature-one  p {color: #fff;
				 font-size: 1.75em;
				 line-height: 35px;

				@media (max-width: 1024px) {
					font-size: 1.5em;
					padding-bottom: 15px;
					line-height: 30px;
					
				 }
	}


.feature-two  p {color: #fff;
				 font-size: 1.75em;
				 line-height: 35px;

				@media (max-width: 1024px) {
					font-size: 1.5em;
					padding-bottom: 15px;
					line-height: 30px;
					
				 }
	} 


.feature-three  p {color: #fff;
				 font-size: 1.75em;
				 line-height: 35px;

				@media (max-width: 1024px) {
					font-size: 1.5em;
					padding-bottom: 15px;
					line-height: 30px;
					
				 }
	} 



.feature-two {
    background-image: url("../img/img2.jpg");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
	padding: 100px;


			@media (max-width: 767px) {
				text-align: center;
				padding: 0px;
			}

			@media (max-width: 1024px) {
				background-position: 90% 50%;
				padding: 0px;
			}
			
			@media (max-width: 1199px){
				padding: 0px;
			}
			
			@media (max-width: 1440px) {
				padding: 15px;
			}
}


.feature-two  h1 {color: #fff;
						font-size: 3em;
						font-weight: 300;
						text-transform: uppercase;
						text-shadow: 3px 3px $secondary;

				@media (min-width: 991px) and (max-width: 1174px) {
					font-size: 2em;
				}
				
	} 



.feature-three {
    background-image: url("../img/img3.jpg");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
	padding: 100px;
	min-height: 621px;

			@media (max-width: 1440px) {
				padding: 15px;
				min-height: 376px;
			}

			@media (max-width: 1024px) {
				background-position: 90% 33%;
				padding: 15px;
				min-height: 376px;
			}

			@media (max-width: 768px) {
				text-align: center;
				padding: 15px;
				min-height: 277px;
			}
    
}

.feature-three  h1 {color: #fff;
						font-size: 3em;
						font-weight: 300;
						text-transform: uppercase;
						text-shadow: 3px 3px $secondary;

				@media (min-width: 991px) and (max-width: 1174px) {
					font-size: 2em;
				}
					
	} 


.sticky-wrapper {height: 108px !important;
					@media (max-width: 767px) {
						height: 90px !important;
					}
				}


/* ======== WHY US =========== */

.why-us {
    background-image: url("../img/lower-banner.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 50% 10%;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    padding: 100px;
	text-align: center;

		@media (max-width: 1440px) {
				padding: 40px;
			}

		@media (max-width: 1199px) {
				padding: 0px 0px;
				background-position: 65% 10%;
			}

		@media (max-width: 1024px) {
				padding: 0px !important;
				background-position: 70% 50%;
				background-attachment: scroll;
			}
	
	
		@media (max-width: 767px) {
				padding: 0px !important;
				 background-position: 10% 10%;
			}

			@media(max-width:400px) {
  				background-position: 66% 10%;
			}
}


.why-us-overlay {
    background-color: rgba(31, 31, 31, 0.55);
	padding: 45px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.75), 0 6px 20px 0 rgba(0, 0, 0, 0.75);
	margin-bottom: 35px;
	height: 100%;
	min-height: 450px;

	
	
	@media (max-width: 767px) {
		padding: 0px;

	}


	@media (max-width: 1024px) {
		padding: 0px;
		min-height: 300px;

	}

	@media (min-width: 1024px) and (max-width: 1440px) {
		padding: 0px;
		min-height: 300px;
	}


	@media (min-width: 1200px) and (max-width: 1361px) {
		padding: 0px;
		min-height: 345px;
	}
}

.why-us h2 {
    color: #fff;
    font-size: 5em;
    font-weight: 300;
    line-height: 70px;
	text-shadow: 3px 3px $secondary;
	padding-bottom: 75px;

	@media (max-width: 1024px) {
		font-size: 4em;
		line-height: 55px;
		padding-bottom: 40px;
	}


}

.why-us h1 {
    color: #fff;
    font-weight: 300;
    line-height: 45px;
	text-shadow: 3px 3px $secondary;
	font-size: 3em;
	padding-bottom: 10px;
	

	@media (max-width: 1024px) {
		line-height: 25px;
		font-size: 2em;
		padding-top: 25px;
		padding-bottom: 5px;
	}

	@media (min-width: 767px) and (max-width: 1024px) {
		font-size: 3em;
	}

	@media (min-width: 1024px) and (max-width: 1199px) {

		font-size: 2em;
	}

	@media (min-width: 1200px) and (max-width: 1361px) {

		font-size: 1.95em;
	}



}

.why-us p {
    color: #fff;
    font-size: 1.75em;
    line-height: 29px;
    font-weight: 300;

	@media (min-width: 767px) and (max-width: 1023px){

		line-height: 40px;
		font-size: 2em;
	}
	
	@media (min-width: 1024px) and (max-width: 1440px) {

		line-height: 25px;
		font-size: 1.5em;
	}
}